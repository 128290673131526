<template>
    <div class="batch-create-department">
        <div class="batch-create" v-loading="loading">
            <div class="batch-create-title">
                编辑组织架构
                <span>快捷键说明：新增节点(Enter/Tab)、删除节点(Backspace)、拖拽可视区域(空格键+鼠标左键)</span>
            </div>
            <div class="batch-create-main">
                <lbd-mindmap 
                    ref="lbdMindmap"
                    v-model="data"
                    :strokeWidth="1"
                    :showUndo="false"
                    :editable="editable"
                    @change="handleChange"
                    @add="handleAdd"
                    @insert="handleInsert"
                    @del="handleDelete"
                    @edit="handleEdit"
                    @move="handleMove"
                    @reparent="handleReparent"
                ></lbd-mindmap>
            </div>
            <div class="batch-create-footer">
                <el-button type="primary" v-if="!editable" @click="enterEditMode">编辑</el-button>
                <el-button type="normal" v-if="editable" @click="handleCancel">取消</el-button>
                <el-button type="primary" class="edit-button" v-if="editable" @click="handleSubmit">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import DepartmentService from '#/js/service/departmentService.js';
import LbdMindmap from 'lbd-mindmap';
export default {
    name: "BatchCreateDepartment",
    components: { 
        LbdMindmap
    },
    data() {
        return {
            loading: false,
            editable: false,
            customerId: '',
            groupVersion: '',
            originData: [],
            data: [],
            create: [],
            update: [],
            delete: []
        }
    },
    computed: {
    },
    mounted() {
        this.customerId = this.$route.params.customerId;
        if(!this.customerId) {
            shortTips('未选择目标客户！');
            return false;
        }
        this.getTree();
    },
    methods: {
        enterEditMode() {
            this.editable = true;
        },
        getTree() {
            this.loading = true;
            this.$refs.lbdMindmap.init();
            DepartmentService.getDepartmentTree({
                customerId: this.customerId
            }).then(res =>{
                this.loading = false;
                this.groupVersion = res.groupVersion;
                let arr = [res.root];
                if(arr[0].children && arr[0].children.length > 0) {
                    arr[0].children.forEach(item => {
                        if(item.children && item.children.length > 0) {
                            item._children = item.children;
                            item.children = [];
                        }
                    })
                }
                this.originData = arr;
                this.data = arr;
                this.$refs.lbdMindmap.mountHandler();
            }).catch(err => {
                this.loading = false;
            })
        },
        getChildren(data) {
            if (data.children && data.children.length > 0) {
                return data.children;
            }
            if (data._children && data._children.length > 0) {
                return data._children;
            }
            return [];
        },
        handleChange(arr) {

        },
        handleAdd(parent, node) {
            console.log('add')
            console.log(parent)
            console.log(node)
            this.handleEdit(node, node.name);
            // this.create.push(node);
        },
        handleEdit(node, name) {
            // console.log('edit')
            // console.log(node)
            node.path = node.path.replace(`-${node.parentId}`, `.${node.parentId}`);
            let deleteIndex = this.find('delete', node);
            if(deleteIndex > -1) {
                //删除节点还原
                this.$refs.lbdMindmap.toUpdate = true;
                // let data = this.findByIndex(node.index);
                let data = this.findByPathAndId(node.path, node.id, false);
                if(data) {
                    data.id = this.delete[deleteIndex].id;
                    data.path = this.delete[deleteIndex].path;
                }
                // this.delete = this.delete.filter(item => {
                //     return item.id != data.id;
                // })
                this.delete.splice(deleteIndex, 1);
                this.create = this.create.filter(item => {
                    return item.id != node.id;
                })
            }else {
                if(node.id.indexOf('-') == -1) {
                    let updateIndex = this.find('update', node);
                    if(updateIndex > -1) {
                        let originData = this.findByPathAndId(node.path, node.id, true);
                        if(originData && originData.name == node.name) {
                            this.update = this.update.filter(item => {
                                return item.id != node.id;
                            })
                        }else {
                            this.update[updateIndex].name = node.name;
                        }
                    }else {
                        this.update.push({
                            name: node.name,
                            id: node.id,
                            path: node.path,
                            parentId: node.parentId
                        })
                    }
                }else {
                    // 新增节点
                    // 判断是否存在同名节点
                    // let parentPath = node.path.slice(0, node.path.lastIndexOf('.'));
                    // let parent = this.findByPathAndId(parentPath, node.parentId, false);
                    // let data = this.findByPathAndId(node.path, node.id, false);
                    // console.log(data)
                    // data.id = 'xxx'
    
                    // let repeatCount = 0;
                    // let repeatList = [];
                    // let max = '';
                    // let curNode = null;
                    // parent.children.forEach(item => {
                    //     if(item.id == node.id) {
                    //         curNode = item;
                    //     }
                    //     if(item.id != node.id && item.name == node.name) {
                    //         repeatCount ++;
                    //     }else if(item.id != node.id && item.name.indexOf(node.name) == 0 && parseInt(item.name.replace(node.name, '')).toString() != 'NaN') {
                    //         repeatList.push(parseInt(item.name.replace(node.name, '')));
                    //     }
                    // })
                    // console.log(repeatCount);
                    // console.log(repeatList);
                    // if(repeatCount > 0 && data) {
                    //     max = repeatList.length > 0 ? Math.max(...repeatList) : 0;
                    //     max ++;
                    //     data.name = data.name + String(max);
                    //     console.log(data.name)
                    // }
                    // data.name = 'xxx';
                    // this.$refs.lbdMindmap.updateName(data, data.name);
                    // console.log(parent);
                    // console.log('---');

                    // console.log(777777)
                    let createIndex = this.find('create', node);
                    // console.log('createIndex'+createIndex);
                    if(createIndex > -1) {
                        // console.log(88888888888)
                        this.create[createIndex].name = name;
                    }else {
                        // console.log(99999999999)
                        // console.log(this.create);
                        this.create.push(node);
                        // console.log(this.create);
                    }
                }
            }
        },
        handleInsert(nodePosition, node) {
            this.handleEdit(node, node.name);
        },
        handleDelete(node) {
            console.log(node)
            if(node.id == 0) {
                return false;
            }
            if(node.id.indexOf('-') > -1) {
                this.create = this.create.filter(item => {
                    return item.id != node.id;
                })
            }else {
                this.update = this.update.filter(item => {
                    return item.id != node.id;
                })
                let deleteNode = { 
                    name: node.name,
                    id: node.id,
                    path: node.path,
                    parentId: node.parentId
                };
                if(deleteNode.path.indexOf('-') == -1) {
                    let data = this.findByPathAndId(deleteNode.path, deleteNode.id, true);
                    if(data) {
                        deleteNode.name = data.name;
                    }
                }
                this.delete.push(deleteNode);
                console.log(this.delete);
            }
            if(this.getChildren(node).length > 0) {
                this.getChildren(node).forEach(item => {
                    this.handleDelete(item);
                })
            }
        },
        handleMove(del, insert) {
            console.log(del);
            console.log(insert)
        },
        handleReparent(parent, node) {
            console.log(parent)
            console.log(node)
            let reparentNode = {
                name: node.name,
                id: node.id,
                path: parent.path ? `${parent.path}.${parent.id}` : parent.id,
                parentId: parent.id
            };
            //更新导图数据实际path
            this.$refs.lbdMindmap.toUpdate = true;
            let data = this.findByIndex(node.index);
            console.log(reparentNode);
            data.path = reparentNode.path;
            data.parentId = reparentNode.parentId;

            if(reparentNode.id.indexOf('-') > -1) {
                //新增节点
                this.create.forEach(item => {
                    if(item.id == reparentNode.id) {
                        item.path = reparentNode.path;
                        item.parentId = reparentNode.parentId;
                    }
                })
            }else {
                //原有节点
                let index = this.find('update', reparentNode);
                if(index > -1) {
                    this.update[index].path = reparentNode.path;
                    this.update[index].parentId = reparentNode.parentId;
                }else {
                    this.update.push(reparentNode);
                }
            }
            if(this.getChildren(node).length > 0) {
                this.getChildren(node).forEach(item => {
                    this.handleReparent(reparentNode, item);
                })
            }
        },
        find(key, node) {
            let nodeIndex = -1;
            this[key].some((item, index) => {
                if(key == 'delete' && item.name == node.name && item.path == node.path) {
                    nodeIndex = index;
                    return true;
                }
                if(key != 'delete' && item.id == node.id) {
                    nodeIndex = index;
                    return true;
                }
            })
            return nodeIndex;
        },
        findByIndex(index) { // 根据index找到数据
            const array = index.split('-').map(n => ~~n)
            let data = this.data[0]
            for (let i = 1; i < array.length; i++) {
                if (data && this.getChildren(data).length > 0) {
                    data = this.getChildren(data)[array[i]]
                } else { // No data matching id
                    return null
                }
            }
            return data
        },
        findByPathAndId(path, id, isOrigin) {
            const array = path.split('.').map(n => ~~n)
            let data = isOrigin ? this.originData : this.data
            for (let i = 0; i < array.length; i++) {
                if(i == 0) {
                    data = data[array[0]];
                }else {
                    if(data && this.getChildren(data).length > 0) {
                        let dataIndex = -1;
                        this.getChildren(data).some((item, index) => {
                            if(item.id == array[i]) {
                                dataIndex = index;
                                return true;
                            }
                        })
                        data = dataIndex > -1 ? data[dataIndex] : null;
                    }
                }
            }
            if(data && this.getChildren(data).length > 0) {
                let dataIndex = -1;
                this.getChildren(data).some((item, index) => {
                    if(item.id == id) {
                        dataIndex = index;
                        return true;
                    }
                })
                data = dataIndex > -1 ? data[dataIndex] : null;
            }
            return data;
        },
        resolveRepeatNodes(node) {
            let parentNode = this.findByIndex(node.index)

        },
        remove(key, node) {
            this[key] = this[key].filter(item => {
                return item.id !== node.id;
            })
        },
        handleCancel() {
            this.editable = false;
            this.$refs.lbdMindmap.toUpdate = true;
            this.data = this.originData;
            this.create = [];
            this.update = [];
            this.delete = [];
        },
        handleSubmit() {
            let deleteList = [];
            this.delete.forEach(item => {
                deleteList.push(item.id);
            })
            this.loading = true;
            DepartmentService.batchEditDepartment({
                customerId: this.customerId,
                groupVersion: this.groupVersion,
                create: this.create,
                update: this.update,
                delete: deleteList
            }).then(res => {
                this.loading = false;
                shortTips('编辑成功！');
                setTimeout(() => {
                    // location.href = `/Headhunting/MyCompany.html#/Customer/${this.customerId}/department`;
                    location.href = `/#/Customer/${this.customerId}/department`;
                }, 1500)
            }).catch(err => {
                this.loading = false;
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.batch-create-department {
    height: 100%;
    &:after {
        content: '© 2023 liebide.com';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 56px;
        line-height: 56px;
        text-align: center;
        font-size: 14px;
        color: #999;
        background-color: #f9f9f9;
    }
    .batch-create {
        position: absolute;
        top: 80px;
        left: 20px;
        right: 20px;
        bottom: 45px;
        display: flex;
        flex-direction: column;

        &-title {
            padding: 0 20px;
            font-size: 16px;
            color: #666666;
            height: 60px;
            line-height: 60px;
            background-color: #fff;
            border-bottom: 1px solid #EEEEEE;
            border-radius: 8px 8px 0 0;

            span {
                margin-left: 20px;
                font-size: 14px;
                color: #38BC9D;
            }
        }

        &-main /deep/{
            flex: 1;
            flex-grow: 1;

            #mindmap {
                height: 100%;

                .gButton {
                    display: none;   
                }

                .node.depth_0 > foreignObject div {
                    background-color: $primary;
                }
                .node:not(.depth_0) > foreignObject, .node:not(.depth_0) > foreignObject div {
                    background-color: #F6F6F6;
                }
                .node.depth_1 > foreignObject, .node.depth_1 > foreignObject div {
                    outline: none;
                    background-color: #E8E8E8;
                }
                #selectedNode:not(.depth_0) > foreignObject, #selectedNode:not(.depth_0) > foreignObject div {
                    background-color: rgba(56, 188, 157, 0.3);
                    // background-color: #fc7859;
                }
                #newParentNode > foreignObject {
                    border-color: #fc7859;
                }

                .gEllipsis .btn {
                    height: 4px;
                }
            }
        }

        &-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100px;
            background-color: #fff;
            border-top: 1px solid #EEEEEE;
            border-radius: 0 0 8px 8px;

            .el-button {
                width: 120px;
                height: 40px;
                border-radius: 4px;
            }
            .edit-button {
                margin-left: 46px;
            }
            // .el-button--primary:last-child {
            //     margin-left: 46px;
            // }
        }
    }
}
</style>